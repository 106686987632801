/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

/* By default hide questions if there are too many */
class Faq {

	// Variables
	public $faq: JQuery;
	public $faqRows: JQuery;
	public faqRowsCount: number;
	public $faqEntries: JQuery;
	public faqEntriesCount: number;
	public $faqMoreButton: JQuery;

	/* Constructor */
	constructor($faq) {

		// Check if $faq exists in DOM
		if ($faq.length) {
			this.$faq = $faq;
			this.$faqRows = $faq.find("> div.faq__row");
			this.faqRowsCount = this.$faqRows.length;
			this.$faqEntries = $faq.find("div.faq-entry");
			this.faqEntriesCount = this.$faqEntries.length;
			this.$faqMoreButton = $faq.find("#faq-questions__more-button");
			this.Setup();
		}

	}

	/* Setup FAQ */
	private Setup() {

		// Initially hide too many questions
		this.HideQuestions(true);

		// Button click event
		this.$faqMoreButton.on("click", (e) => {
			if (this.$faqMoreButton.hasClass("hidden")) {
				this.ShowQuestions();
			} else {
				this.HideQuestions();
			}
		});

	}

	/* Check screen size and how many questions should be hidden */
	private CheckQuestionsToHideCount(): number {

		// Check window width
		var windowWidth: number = _helpers.WindowGetWidth();

		// Count for various screen sizes
		if (windowWidth < 820) {
			if (this.faqEntriesCount > 2) {
				return this.faqEntriesCount - 2;
			} else {
				return 0;
			}
		} else {
			if (this.faqEntriesCount > 4) {
				return this.faqEntriesCount - 4;
			} else {
				return 0;
			}
		}
	}

	/* Hide some questions */
	private HideQuestions(init: boolean = false) {

		// Get quantity to hide
		var hideCount: number = this.CheckQuestionsToHideCount();

		// Reverse order of FAQ entries
		var faqEntriesReverse: Array<HTMLDivElement>  = this.$faqEntries.get().reverse();
		var faqEntriesReverseLength: number = faqEntriesReverse.length;

		// Iterate FAQ entries in reverse order
		var i: number;
		for (i = 0; i < faqEntriesReverseLength - hideCount; i++) {
			var $faqEntry: JQuery = $(faqEntriesReverse[i]);
			if (init === true) {
				$faqEntry.hide();
			} else {
				$faqEntry.slideUp(500);
			}
		}

		// Change button
		this.$faqMoreButton.toggleClass("hidden");

		// Remove button if quantity to hide is less than 0
		if (hideCount <= 0) {
			this.$faqMoreButton.remove();
		}

	}

	/* Show more questions */
	private ShowQuestions() {

		// Iterate through FAQ questions
		this.$faqEntries.each(function () {
			$(this).slideDown(500);
		});

		// Change button
		this.$faqMoreButton.toggleClass("hidden");

	}

}

// Create instance of FAQ class
var faq: Faq = new Faq($("#faq-questions"));
