/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />
/// <reference path="navigation.ts" />
/// <reference path="footer.ts" />
/// <reference path="banners.ts" />
/// <reference path="faq.ts" />
/// <reference path="404.ts" />

// Remove no-js class from document
$("html").removeClass("no-js");
