/// <reference path="../../typings/tsd.d.ts" />

/* Fill span text to div interface */
interface IFillSpanTextToDivOptions {
	maxFontSizeInPixels?: number;
}

/* Helpers class */
class Helpers {

	// Variables
	public $html: JQuery;
	public $body: JQuery;
	public $window: JQuery;

	/* Constructor */
	constructor () {

		// Assign variables
		this.$html = $("html");
		this.$body = $("body");
		this.$window = $(window);

	}

	/* Get window height */
	public WindowGetHeight(): number {
		return this.$window.outerHeight();
	}

	/* Get window width */
	public WindowGetWidth(): number {
		return this.$window.outerWidth();
	}

	/* Get window scroll top position */
	public ScrollGetTopPosition(): number {
		return $(document).scrollTop();
	}

	/* Output number with leading zeros */
	public OutputNumberWithLeadingZeros(value: number, numberOfCharacters: number): string {
		var output: string = value.toString();
		while (output.length < numberOfCharacters) {
			output = "0" + output;
		}
		return output;
	}

	/* Fill text to the div */
	public FillSpanTextToDiv($div: JQuery, options: IFillSpanTextToDivOptions): void {

		// Options
		var maxFontSizeInPixels: number = ("maxFontSizeInPixels" in options) ? options["maxFontSizeInPixels"] : 128;
		var $text: JQuery = $div.find("> span:eq(0)");

		// Check if text exists
		if ($text.length) {

			// Div dimensions
			var divWidth: number = $div.width();
			var divHeight: number = $div.height();

			// Text dimensions
			var textWidth: number = 0;
			var textHeight: number = 0;
			var textFontSize;
			var textFontSizeInPixels;

			// Change textfont size to base value
			$text.css("font-size", "0px");

			/* Change font size */
			function ChangeFontSize(byValue: number) {

				// Get current font size
				textFontSize = $text.css("font-size");
				textFontSizeInPixels = parseInt(textFontSize);

				// Change to new value
				$text.css("font-size", (textFontSizeInPixels + byValue) + "px");

				// Verify font size
				CheckFontSize();

			}

			/* Check if font size is big enough */
			function CheckFontSize(): void {

				// Get values
				textWidth = $text.width();
				textHeight = $text.height();

				// Check if text size if smaller than container size
				if (textHeight > divHeight) {
					if (Math.abs(textHeight - divHeight) > 6) {
						ChangeFontSize(-1);
					}
				}
				else if (textWidth > divWidth) {
					if (Math.abs(textWidth - divWidth) > 6) {
						ChangeFontSize(-1);
					}
				}
				else {
					ChangeFontSize(+10);
				}

			}

			// Begin
			CheckFontSize();

		}

	}

	/* Replace email with mailto link */
	public ReplaceTextEmailWithMailtoLink($emailText: JQuery): void {

		// Check if container exists
		if ($emailText.length) {

			// Prepare variables
			var email: string;

			// Get text
			email = $emailText.text().trim();
			email = email.replace(/\[at\]/gi, "@");
			email = email.replace(/\[dot\]/gi, ".");
			email = email.replace(/ /g, "");

			// If span, replace it with link
			if ($emailText.is("span")) {
				$emailText.replaceWith("<a class=\"email " + $emailText.attr("class") + "\" href=\"mailto:" + email + "\">" + email + "</a>");
			} else {
				$emailText.append("<a class=\"email " + $emailText.attr("class") + "\" href=\"mailto:" + email + "\">" + email + "</a>");
			}

		}

	}

	/* Change top and bottom padding of element */
	public ChangeTopAndBottomPadding($el: JQuery, value: number, unit: string = "rem"): void {
		$el.css({
			"padding-top": value + unit,
			"padding-bottom": value + unit
		});
	}

}

// Invoke helpers class
var _helpers: Helpers = new Helpers();

/* Ultra simple jQuery plugin for reversing jQuery object order */
jQuery.fn.reverse = [].reverse;
