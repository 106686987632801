/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

/* Footer class */
class Footer {

	// Variables
	public $footer: JQuery;
	public $emailContainer: JQuery;
	public $phoneContainer: JQuery;
	public $goToTop: JQuery;

	/* Constructor */
	constructor($footer: JQuery) {

		// Initialize
		if ($footer.length) {
			this.$footer = $footer;
			this.$emailContainer = this.$footer.find("#footer__contact__list__entry--email");
			this.$phoneContainer = this.$footer.find("#footer__contact__list__entry--phone");
			this.$goToTop = this.$footer.find("#footer__copyright__gototop");
			this.Setup();
		}

	}

	/* Footer setup */
	private Setup(): void {

		// Change text email to mailto link
		_helpers.ReplaceTextEmailWithMailtoLink(this.$emailContainer.find("span.text"));

		// Add scroll to top behavior to the button
		this.$goToTop.on("click", function (e) {
			$("html, body").animate({scrollTop: 0}, 800);
			e.preventDefault();
		})

	}

}

// Initialize footer
var $footer: Footer = new Footer($("#footer"));
