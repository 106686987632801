/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

class Features {

	// Variables
	public $featuresList: JQuery;
	public $featuresEntries: JQuery;
	public $featuresTopNavigation: JQuery;
	public $featuresSidebarNavigation: JQuery;

	// Scroll
	private scrollPosition: number = 0;
	private scrollOrientationToBottom: boolean = true;

	/* Constructor */
	constructor($featuresList) {

		// Check if features list exists
		if ($featuresList.length) {
			this.$featuresList = $featuresList;
			this.$featuresEntries = this.$featuresList.find("> div.feature__entry");
			this.$featuresTopNavigation = $("#features__shortcuts__container");
			this.$featuresSidebarNavigation = $("#features-list-navigation");
			this.Setup();
		}
	}

	/* Setup */
	private Setup(): void {

		// This
		var self = this;

		// Change spacing of rows
		this.AlignRowsSpacing();

		// Align sidebar navigation
		this.AlignSidebarNavigation();

		// Assign click event to top navigation
		this.$featuresTopNavigation.find("div.feature__shortcut a.feature__shortcut__link").on("click", function (e) {
			self.NavigateToFeature($(this).attr("href"));
			e.preventDefault();
		});

		// Assign click event to sidebar navigation
		this.$featuresSidebarNavigation.find("> a").on("click", function (e) {
			self.NavigateToFeature($(this).attr("href"));
			e.preventDefault();
		});

		// Change active row on scroll
		$(window).scroll( function () {

			// Current scroll position
			var currentScrollTop: number = _helpers.ScrollGetTopPosition();

			// When scrolling to the bottom
			if (currentScrollTop + 100 > self.scrollPosition) {
				self.scrollOrientationToBottom = true;
				self.scrollPosition = currentScrollTop;
				self.FindActiveFeature();
			}
			// When scrolling to the top
			else if (currentScrollTop - 100 < self.scrollPosition) {
				self.scrollOrientationToBottom = false;
				self.scrollPosition = currentScrollTop;
				self.FindActiveFeature();
			}

		});

	}

	/* Spacing of rows */
	public AlignRowsSpacing(): void {

		// Get window height
		var windowHeight: number = _helpers.WindowGetHeight();

		// Get first entry
		var $firstFeatureEntry: JQuery = this.$featuresEntries.first();

		// Padding and height
		var paddingInRem: number = 3;
		var firstFeatureEntryHeight: number = 0;

		// Increase padding as long as it does fit in the window
		while (firstFeatureEntryHeight < windowHeight - 320) {

			// Change padding of the first entry
			_helpers.ChangeTopAndBottomPadding($firstFeatureEntry, paddingInRem, "rem");

			// Increase height
			firstFeatureEntryHeight = $firstFeatureEntry.outerHeight();

			// Increase padding
			paddingInRem++;

		}

		// Change padding for all entries
		this.$featuresEntries.each( function () {
			_helpers.ChangeTopAndBottomPadding($(this), paddingInRem, "rem");
		});

	}

	/* Align sidebar navigation */
	public AlignSidebarNavigation(): void {
		var sidebarNavigationHeight: number = this.$featuresSidebarNavigation.outerHeight();
		this.$featuresSidebarNavigation.css("margin-top", (sidebarNavigationHeight / 2 * -1));
	}

	/* Navigate to feature */
	private NavigateToFeature(query: string) {

		var $el: JQuery = $(query);

		if ($el.length) {

			// Get values
			var elPos: number = $el.offset().top;
			var elHeight: number = $el.outerHeight();
			var windowHeight: number = _helpers.WindowGetHeight();

			// Determine position to scroll to
			var scrollTo: number = elPos;
			if (elHeight < windowHeight) {
				scrollTo = elPos - (windowHeight - elHeight) / 2;
			} else {
				scrollTo = elPos;
			}

			// Animate scroll
			$("html, body").animate({
				scrollTop: scrollTo + "px"
			}, 600);

		}
	}

	/* Find currently active feature */
	private FindActiveFeature(): void {

		// This
		var self = this;

		// Get window and document parameters
		var windowHeight: number = _helpers.WindowGetHeight();
		var windowHeightHalf: number = windowHeight / 2;
		var windowHeightProportion: number = windowHeightHalf * 0.3;
		var scrollPos: number = _helpers.ScrollGetTopPosition();
		var scrollMiddle: number = scrollPos + windowHeightHalf;
		var scrollEnd: number = scrollPos + windowHeight;

		// Iterate through features
		this.$featuresEntries.each( function () {

			// Current entry
			var $entry: JQuery = $(this);

			// Entry height and position
			var entryHeight: number = $entry.outerHeight();
			var entryHeightHalf: number = entryHeight / 2;
			var entryPos: number = $entry.offset().top;
			var entryMiddle: number = entryPos + entryHeightHalf;
			var entryEnd: number = entryPos + entryHeight;

			// Determine if user is scroll to bottom or to top
			if (self.scrollOrientationToBottom === true) {
				if (scrollMiddle - windowHeightProportion >= entryPos) {
					SetEntryAsActive($entry);
				}
			}
			else {
				if (scrollMiddle + windowHeightProportion <= entryEnd) {
					SetEntryAsActive($entry);
					return false;
				}
			}

		});

		/* Make it active */
		function SetEntryAsActive($el: JQuery): void {
			$el.addClass("active").siblings().removeClass("active");
			self.$featuresSidebarNavigation.find("> a:eq(" + $el.index() + ")").addClass("active").siblings().removeClass("active");
		}

	}

}

// Initialize features class
var features: Features = new Features($("#features-list"));
