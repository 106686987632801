/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

var $pageNotFoundBackground: JQuery = $("#page-not-found__background");
if ($pageNotFoundBackground.length)
{
	var fillOptions: IFillSpanTextToDivOptions = {
		maxFontSizeInPixels: 999
	};
	_helpers.FillSpanTextToDiv($pageNotFoundBackground, fillOptions);
}
