/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

/* Enum for navigation behaviors */
enum EnumNavigationBehaviors {
	"static" = 0,
	"fixed" = 1
}

/* Navigation class */
class Navigation {

	// Variables
	public $el: JQuery;
	public $elTop: JQuery;
	public $elContent: JQuery;
	public behavior: EnumNavigationBehaviors;

	/* Constructor */
	constructor($navigation: JQuery, behavior: EnumNavigationBehaviors) {

		// Verify if navigation element exists
		if ($navigation.length) {

			// Set class variables
			this.$el = $navigation;
			this.$elTop = $navigation.find("> .navigation__top");
			this.$elContent = $navigation.find("> .navigation__content");
			this.behavior = behavior;

			// Implement scrolling behavior and look for
			if (this.behavior === EnumNavigationBehaviors.fixed) {
				// this.NavigationScrollBehavior();
			}

			// Menu button functionality
			this.NavigationMenu();

		}

	}

	/* Scrolling behavior for fixed navigation */
	private NavigationScrollBehavior(): void {

		// Find out if banners exist
		var $banners: JQuery = $("#banners");
		var bannersHeight: number = 0;
		if ($banners.length) {
			bannersHeight = $banners.outerHeight();
		}

		// Find out if static navigation exists
		var $navigationStatic: JQuery = $("#navigation--main");
		var navigationStaticHeight: number = 0;
		if ($navigationStatic.length) {
			navigationStaticHeight = $navigationStatic.outerHeight();
		}

		// Combine height
		var combinedHeight = bannersHeight + navigationStaticHeight;

		// Create scroll event
		_helpers.$window.scroll (function () {
			if (_helpers.$window.scrollTop() > combinedHeight) {
				_helpers.$html.addClass("show-navigation-fixed");
			} else {
				_helpers.$html.removeClass("show-navigation-fixed");
			}
		});

	}

	/* Hidden menu button */
	private NavigationMenu(): void {

		// Check if button exists
		var $menuButton = this.$el.find("a.navigation__menu");
		if ($menuButton.length) {

			$menuButton.on("click", (e) => {

				// Toggle menu status
				this.ShowOrHideNavigationMenu();

				// Prevent default
				e.preventDefault();

			});

		}

	}

	/* Show or hide fixed navigation menu */
	public ShowOrHideNavigationMenu(): void {

		// Reset content position
		this.$elContent.css("transform", "translateY(0px)");

		// Close
		if (this.$el.hasClass("expanded")) {
			this.$el.removeClass("expanded");
			this.$el.css("height", "70px");
			this.$elContent.css("transform", "translateY(0px)");
		}
		// Open
		else {
			var windowHeight: number = _helpers.$window.outerHeight();
			var elContentHeight: number = this.$elContent.outerHeight();
			var elContentPosition: number = (windowHeight / 2) - (elContentHeight / 2) - this.$elTop.outerHeight();
			this.$el.addClass("expanded");
			this.$el.css("height", windowHeight + "px");
			this.$elContent.css("transform", "translateY(" + elContentPosition.toString() + "px)");
		}
	}

}

// Invoke fixed navigation
var navigationFixed: Navigation = new Navigation($("#navigation--fixed"), EnumNavigationBehaviors.fixed);
