/// <reference path="../../typings/tsd.d.ts" />
/// <reference path="helpers.ts" />

/* Banner class */
class Banner {

	// Variables
	public $banner: JQuery;
	public bannerHeight: number;
	public $bannersContainer: JQuery;
	public $banners: JQuery;
	public $bannersNavigation: JQuery;
	public $bannersNavigationLinks: JQuery;
	public bannersNavigationLinksLength: number;
	public $bannersNavigationArrowLeft: JQuery;
	public $bannersNavigationArrowRight: JQuery;
	public $bannersTimer: JQuery;
	public bannerTimeout: number;
	public bannerTimerInterval;

	/* Constructor */
	constructor($banner) {

		// If banner exists
		if ($banner.length) {
			this.$banner = $banner;
			this.$bannersContainer = this.$banner.find("> #banners__container");
			this.$banners = this.$bannersContainer.find("> .banner");
			this.$bannersNavigation = this.$banner.find("#banners__navigation");
			this.$bannersNavigationLinks = this.$bannersNavigation.find("> a.banners__navigation__button");
			this.bannersNavigationLinksLength = this.$bannersNavigationLinks.length;
			this.$bannersNavigationArrowLeft = this.$banner.find("#banners__arrow--left");
			this.$bannersNavigationArrowRight = this.$banner.find("#banners__arrow--right");
			this.$bannersTimer = this.$banner.find("> #banners__timer");
			this.bannerTimeout = 8000;
			this.Setup();
		}

	}

	/* Setup banners */
	private Setup() {

		// Reference this
		var self = this;

		// Mark as initialized
		this.$banner.addClass("js");

		// Align banner
		this.AlignBanners();

		// Start timer
		// this.StartCanvasTimer();

		// Init banners navigation
		this.$bannersNavigationLinks.on("click", function (e) {
			// if ($(this).hasClass("active")) {
				// self.StartCanvasTimer($(this).index());
			// } else {
				self.ChangeBanner($(this).index());
			// }
			e.preventDefault();
		});

		// Init banners navigation arrows
		this.$bannersNavigationArrowLeft.on("click", function (e) {
			self.ChangeBannerToPrev();
			e.preventDefault();
		});
		this.$bannersNavigationArrowRight.on("click", function (e) {
			self.ChangeBannerToNext();
			e.preventDefault();
		});

		// When window is resized
		_helpers.$window.on("resize", () => {
			this.AlignBanners();
		});

	}

	/* Align banners */
	public AlignBanners() {

		// Self
		var self = this;

		// Get sizing information
		var windowWidth: number = _helpers.WindowGetWidth();
		var windowHeight: number = _helpers.WindowGetHeight();

		// Resize banners container
		var bannersHeight: number = windowWidth / 2 * 1.2;
		if (bannersHeight < 480 || bannersHeight > windowHeight) {
			if (bannersHeight > windowHeight) {
				bannersHeight = windowHeight * 0.85;
			} else {
				bannersHeight = 480;
			}
		}
		this.bannerHeight = bannersHeight;
		this.$banner.css("height", bannersHeight);

		// Align active banner headline
		this.AlignBannersHeadline(this.$banners.filter(".active"));

	}

	/* Align banner headline */
	public AlignBannersHeadline($banner): void {

		// Get elements
		var $headline = $banner.find(".banner__headline");

		// Dimensions
		var bannerHeight: number = $banner.outerHeight();
		var headlineHeight: number = $headline.outerHeight();
		var timerHeight: number = this.$bannersTimer.outerHeight();

		// Calculate position
		var headlinePosition: number = (bannerHeight / 2) - (headlineHeight / 2);

		// Position headline
		$headline.css("transform", "translateY(" + headlinePosition + "px)");

		// Position navigation
		this.$bannersNavigation.css("top", (headlinePosition + headlineHeight + 48) + "px");

		// Position timer
		this.$bannersTimer.css("top", (headlinePosition - timerHeight - 48) + "px");

		// Position background
		_helpers.FillSpanTextToDiv($headline.siblings(".banner__background"), {maxFontSizeInPixels: 512});

	}

	public ChangeBanner(value: number): void {
		// this.StartCanvasTimer(value);
		this.ChangeNavigationLink(value);
		this.ChangeBannerImage(value);
		this.AlignBanners();
	}

	public ChangeBannerToPrev(): void {
		var value: number = this.$bannersNavigationLinks.filter(".active").index() - 1;
		if (value < 0) {
			value = this.bannersNavigationLinksLength - 1;
		}
		this.ChangeBanner(value);
	}

	public ChangeBannerToNext(): void {
		var value: number = this.$bannersNavigationLinks.filter(".active").index() + 1;
		if (value >= this.bannersNavigationLinksLength) {
			value = 0;
		}
		this.ChangeBanner(value);
	}

	/* Change navigation link */
	private ChangeNavigationLink(value: number): void {
		this.$bannersNavigationLinks.filter(".active").removeClass("active");
		this.$bannersNavigationLinks.eq(value).addClass("active");
	}

	/* Change banner image */
	private ChangeBannerImage(value: number): void {

		// Self
		var self = this;

		// Get banners
		var $bannerActive = this.$banners.filter(".active");
		var $bannerNew = this.$banners.eq(value);

		// Hide active banner
		$bannerActive.removeClass("active");
		$bannerActive.addClass("inactive");
		setTimeout(function() {
			$bannerActive.removeClass("inactive");
		}, 1200);

		// Show new banner
		$bannerNew.addClass("active");

	}

	/* Canvas timer */
// 	private StartCanvasTimer(value: number = 0, time: number = 10000): void {
//
// 		// Clear existing interval
// 		if (typeof this.bannerTimerInterval === "number") {
// 			clearInterval(this.bannerTimerInterval);
// 		}
//
// 		// Canvas variables
// 		var canvas: HTMLCanvasElement = <HTMLCanvasElement>this.$bannersTimer[0];
// 		var canvasWidth: number = canvas.width, canvasHeight: number = canvas.height;
// 		var canvasWidthHalf: number = canvasWidth / 2, canvasHeightHalf: number = canvasHeight / 2;
// 		var context2d: CanvasRenderingContext2D = canvas.getContext("2d");
// 		var circleStrokeColor: string = "#C6C4BE", circleStrokeWidth: number = 1, circleRadius: number = canvasWidthHalf - circleStrokeWidth;
// 		var image: ImageData;
// 		var draw: Function;
// 		var drawProgress: Function;
//
// 		// Clear canvas
// 		context2d.clearRect(0, 0, canvasWidth, canvasHeight);
//
// 		// Create background circle
// 		context2d.beginPath();
// 		context2d.arc(canvasWidthHalf, canvasHeightHalf, circleRadius, 0 * Math.PI, 2 * Math.PI, true);
// 		context2d.lineWidth = circleStrokeWidth;
// 		context2d.lineCap = "square";
// 		context2d.strokeStyle = "#EFEEEA";
// 		context2d.stroke();
// 		context2d.closePath();
//
// 		// Create initial text
// 		context2d.font = "300 20px Roboto";
// 		context2d.textAlign = "center";
// 		context2d.fillStyle = "#0A4D6D";
// 		context2d.fillText(_helpers.OutputNumberWithLeadingZeros(value + 1, 2), canvasWidthHalf, canvasHeightHalf + 8);
//
// 		// Get initial image data
// 		image = context2d.getImageData(0, 0, canvasWidth, canvasHeight);
//
// 		// Setup progress circle properties
// 		context2d.strokeStyle = circleStrokeColor;
//
// 		// Draw the circle with progress
// 		draw = (progress: number) => {
// 			context2d.putImageData(image, 0, 0);
// 			context2d.beginPath();
// 			context2d.arc(canvasWidthHalf, canvasHeightHalf, canvasWidthHalf - circleStrokeWidth, 1 * Math.PI, progress * Math.PI, false);
// 			context2d.stroke();
// 			context2d.closePath();
// 		};
//
// 		// Determine draw progress value from percentage
// 		drawProgress = (percentage: number): number => {
// 			return (percentage / 100) * 2 + 1;
// 		};
//
// 		// Animate
// 		var intervalSum: number = 0;
// 		var intervalTime: number = 50;
// 		var intervalEnd: number = this.bannerTimeout;
// 		var intervalStep: number = 0;
// 		var intervalStepValue: number = intervalEnd / intervalTime;
// 		this.bannerTimerInterval = setInterval( () => {
//
// 			// Animate
// 			draw(drawProgress(intervalSum / intervalEnd * 100));
//
// 			// Add to interval sum
// 			intervalSum = intervalSum + intervalTime;
// 			intervalStep++;
//
// 			// Stop interval and switch to next banner
// 			if (intervalSum > intervalEnd) {
// 				clearInterval(this.bannerTimerInterval);
// 				this.ChangeBannerToNext();
// 			}
//
// 		}, intervalTime);
//
// 	}

}

var banner: Banner = new Banner($("#banners"));
